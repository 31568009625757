// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const aws_mobile = {
    aws_appsync_graphqlEndpoint:
      'https://iwd65sr7n5emdgtnb75oifdzge.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_user_files_s3_bucket:
      'kiwichatappstack-kiwichatmsgimgsaa0ddb06-exm7cicfmv7s',
    aws_user_files_s3_bucket_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_rLIBv3uI5',
    aws_user_pools_web_client_id: '524lfgju88uhpc8f24ai9i48ak',
    aws_cognito_identity_pool_id:
      'us-east-1:4ddc6aa2-5dbc-4a22-a4f1-c9371e635931',
    API: {
      endpoints: [
        {
          name: 'accMgmtApiGateway',
          endpoint: 'https://acc-mgmt-api.kiwichat.com'
        },
        {
          name: 'documentApiGateway',
          endpoint: 'https://documents.kiwichat.com',
        },
      ],
    },
    Storage: {
      messageImgs: {
        bucket: 'kiwichatappstack-kiwichatmsgimgsaa0ddb06-exm7cicfmv7s',
        region: 'us-east-1',
      },
      videoRecordings: {
        bucket: 'kiwichatappstack-kiwichatvideorecordings69c634a1-1wwidgs5h8kg8',
        region: 'us-east-1',
      },
      docReportFiles: {
        bucket: 'kiwichatappstack-kiwichatdocreportfilescee2c3f8-u2xwkmbwj1dg',
        region: 'us-east-1',
      }, // for doc report files
      grievanceAndForm: {
        bucket: 'kiwichatappstack-kiwichatgrievanceandformfilesed8-x4da3rzryqz8',
        region: 'us-east-1',
      },
      bulletins: {
        bucket: 'kiwichatappstack-kiwichatbulletinfilesfea9a418-r21wyr6xmkhk',
        region: 'us-east-1',
      },
      videoApp: {
        bucket: 'kiwichatappstack-kiwichatvideorecordings69c634a1-1wwidgs5h8kg8',
        region: 'us-east-1',
      },
      documents: {
        bucket: 'kiwichatappstack-kiwichatdocumentsde3420b0-16rm1pqut2n63',
        region: 'us-east-1',
      },
      userPhotos: {
        bucket: 'kiwichatappstack-kiwichatuserphoto2406ec8a-w97k6k5u7zib',
        region: 'us-east-1',
      }
    },
    logToSentry: true,
    environment: 'production', // dev | test | uat | production
    Sentry: {
      api_base_url: 'https://o1416691.ingest.sentry.io',
      project_id: '6760568',
      sentry_key: 'd9d3e94f72504f04a49f7ae9353c67c2',
    },
  
    AppInfo: {
      platform: 'WEB',
      platformVersion: '0.0.0',
      version: '0.0.0',
    },
    kiwi: {
      logLevel: 'DEBUG',
    },
    enviroment: {
      env: 'PROD',
    },
  };
  
  export default aws_mobile;
  